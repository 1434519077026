import dayjs from "dayjs";
import {Cookies} from "react-cookie-consent";
import {getDomain} from "./AccessToken";

const AD_TYPE = {
  NAVER: 'NAVER',
  HOCOM_GUEST: 'HCB',
  HOCOM_MEMBER: 'HCB_M',
  TBZ_CRM: 'TBZ_CRM',
  IOS: 'IOS',
  ANDROID: 'ANDROID',
};
const SOURCE_NAME = {
  NAVER : 'NAVER_HOTEL_BTOZ',
  HOCOM: 'HOTELSCOMBINED_BTOZ',
  KAYAK: 'KAYAK_BTOZ'
}

const REFERER_URL = {
  NAVER : ['hotel.naver.com', 'm-tour.store.naver.com', 'stage-hotels.naver.com', 'hotels.naver.com'],
  HOCOM: 'hotelscombined',
  KAYAK: 'kayak'
}

const EXPIRE_DAY = 30;
const EXCLUDE_CRM = [AD_TYPE.IOS, AD_TYPE.ANDROID, AD_TYPE.TBZ_CRM];
const INCLUDE_CRM = [AD_TYPE.NAVER, AD_TYPE.HOCOM_GUEST, AD_TYPE.HOCOM_MEMBER];
const EXCLUDE_UTM_SOURCE = [SOURCE_NAME.NAVER, SOURCE_NAME.HOCOM, SOURCE_NAME.KAYAK]
const REMOVE_PARAM = ['utm_source', 'utm_campaign', 'naverLabel', 'hchid', 'conversionID',  'isnaver', 'providerCode', 'type', 'ut', 'utm_medium', 'utm_content', 'utm_term', 'NaPm'];

export const STORAGE_KEY = {
  T_META_KEY: "t_meta",
  AD_SOURCE_EVENT_KEY: 'ad_source_event'
}

export const COOKIE_KEY = {
  TBMETA: "tbmeta",
  MARKETING_META: 'marketing_meta',
  CAMPAIGN: "campaign"
}

export function setAdSource(router, utm_source, utm_campaign) {
  const {id, naverLabel, hchid, conversionID, label, isnaver, providerCode, type, ut, utm_medium } = router.query;
  if(utm_source){
    const adSourceInfo = {
      utm_source : utm_source,
      hc_id : hchid ? hchid : 0,
      conversionID : conversionID ? conversionID : label ? label : 0,
      utm_campaign : utm_campaign ? utm_campaign : '',
      naver_event_type : isnaver ? isnaver : 0,
      naver_label : naverLabel ? naverLabel : '',
      tbz_hotelId : id ? id : 0,
      tb_meta: AD_TYPE.NAVER,
      provider_code: providerCode ? providerCode : '',
      type: type ? type : '',
      user_type: ut ? ut : '',
      utm_medium: utm_medium ? utm_medium : '',
      create_date : dayjs()
    }
    if(isnaver && isnaver > 0){
      adSourceInfo.ext_opts = naverLabel ? 'N1' : 'N0';
    }

    localStorage.setItem(STORAGE_KEY.AD_SOURCE_EVENT_KEY, JSON.stringify(adSourceInfo));
  }else{
    delExpireInfo(STORAGE_KEY.AD_SOURCE_EVENT_KEY);
  }
}

export function changeTbMetaByCrm(){
  const tbMetaInfo = localStorage.getItem(STORAGE_KEY.T_META_KEY)
  if(tbMetaInfo){
    const info = JSON.parse(tbMetaInfo);
    if(info.meta_type && INCLUDE_CRM.includes(info.meta_type)){
      info.meta_type = AD_TYPE.TBZ_CRM;
      info.marketing_meta = '';
      info.create_date = dayjs();

      localStorage.removeItem(STORAGE_KEY.AD_SOURCE_EVENT_KEY);
      localStorage.setItem(STORAGE_KEY.T_META_KEY, JSON.stringify(info));
      setCookie(AD_TYPE.TBZ_CRM, '', '');
    }else{
      delExpireInfo(STORAGE_KEY.T_META_KEY);
    }
  }
}

export function getNaverAdInfo() {
  const adSourceEventInfo = localStorage.getItem(STORAGE_KEY.AD_SOURCE_EVENT_KEY);
  if(adSourceEventInfo){
    const info = JSON.parse(adSourceEventInfo);

    if(info.create_date) {
      const expire = dayjs().diff(dayjs(info.create_date),"days");
      if(info.utm_source == SOURCE_NAME.NAVER && expire <= EXPIRE_DAY){
        return info;
      }
    }
    localStorage.removeItem(STORAGE_KEY.AD_SOURCE_EVENT_KEY);
  }
}

export function delExpireInfo(key){
  const info = window.localStorage.getItem(key);
  if(info && JSON.parse(info).create_date) {
    const expire = dayjs().diff(dayjs(JSON.parse(info).create_date),"days");
    if(expire >= EXPIRE_DAY){
      window.localStorage.removeItem(key);
    }
  }
}

export function setTbMeta(router, referUrl) {
  const {utm_source, utm_campaign, isnaver} = router.query;
  if (utm_source) {
    const tb_meta = {};

    const isNaver = referUrl ?
        REFERER_URL.NAVER.filter((url) => {
          if(referUrl.includes(url)){
            return true;
          }
        })?.length > 0 : false;
    const isHotelCombine = referUrl ? referUrl.includes(REFERER_URL.HOCOM) : false;
    const isKayak = referUrl ? referUrl.includes(REFERER_URL.KAYAK) : false;

    // if(isNaver && AD_TYPE.HOCOM_GUEST == utm_source){
    if (AD_TYPE.HOCOM_GUEST == utm_source && isnaver && ( isnaver > 0 || isNaver)) {
      tb_meta.meta_type = AD_TYPE.NAVER;
      tb_meta.marketing_meta = SOURCE_NAME.NAVER;

      if (utm_campaign == AD_TYPE.HOCOM_GUEST) {
        const campaign = isnaver ? 'IS_NAVER_' + isnaver : 'NO_PARAMETER';
        setAdSource(router, SOURCE_NAME.NAVER, campaign);
        setCookie(AD_TYPE.NAVER, SOURCE_NAME.NAVER, campaign);
      } else {
        const campaign = utm_campaign ? utm_campaign : '';
        setAdSource(router, SOURCE_NAME.NAVER, campaign);
        setCookie(AD_TYPE.NAVER, SOURCE_NAME.NAVER, campaign);
      }
      // }else if(isHotelCombine && AD_TYPE.HOCOM_GUEST == utm_source){
    } else if (AD_TYPE.HOCOM_GUEST == utm_source && isHotelCombine) {
      const metaType = utm_campaign == 'MOD' ? AD_TYPE.HOCOM_MEMBER : AD_TYPE.HOCOM_GUEST;
      tb_meta.meta_type = metaType
      tb_meta.marketing_meta = SOURCE_NAME.HOCOM;

      if (utm_campaign == AD_TYPE.HOCOM_GUEST) {
        setAdSource(router, SOURCE_NAME.HOCOM, 'NORMAL');
        setCookie(metaType, SOURCE_NAME.HOCOM, 'NORMAL');
      } else {
        setAdSource(router, SOURCE_NAME.HOCOM, utm_source);
        setCookie(metaType, SOURCE_NAME.HOCOM, utm_source);
      }
      // }else if(isKayak && AD_TYPE.HOCOM_GUEST == utm_source && utm_source == AD_TYPE.HOCOM_GUEST && utm_campaign == AD_TYPE.HOCOM_GUEST ){
    } else if (AD_TYPE.HOCOM_GUEST == utm_source && utm_campaign == AD_TYPE.HOCOM_GUEST && isKayak) {
      tb_meta.meta_type = AD_TYPE.HOCOM_GUEST;
      tb_meta.marketing_meta = SOURCE_NAME.KAYAK;

      setAdSource(router, SOURCE_NAME.KAYAK, SOURCE_NAME.KAYAK);
      setCookie(AD_TYPE.HOCOM_GUEST, SOURCE_NAME.KAYAK, SOURCE_NAME.KAYAK);

    } else if (!EXCLUDE_UTM_SOURCE.includes(utm_source)) {
      tb_meta.meta_type = utm_source;
      tb_meta.marketing_meta = '';

      setCookie(utm_source, '', '');
      window.localStorage.removeItem(STORAGE_KEY.AD_SOURCE_EVENT_KEY);
    }

    if (tb_meta.meta_type) {
      tb_meta.create_date = dayjs();
      localStorage.setItem(STORAGE_KEY.T_META_KEY, JSON.stringify(tb_meta));
      removeQueryParam(router, REMOVE_PARAM);
    }
    delExpireInfo(STORAGE_KEY.T_META_KEY);
  }
}

export function removeQueryParam(router, removeParams){
  const { pathname, query } = router;
  const params = new URLSearchParams(query);
  if(removeParams){
    removeParams.map((param) => {
      params.delete(param);
    });
  }
  router.replace(
      { pathname, query: params.toString() },
      undefined,
      { shallow: true }
  );
}

export function getStorageInfo(key) {
  const adSourceEventInfo = localStorage.getItem(key);
  if(adSourceEventInfo){
    return JSON.parse(adSourceEventInfo);
  }
}


export function setCookie(tbMEta, marketingMeta, campaign) {
  const domain = getDomain();
  const expireTime = dayjs().add(30, "days").toDate();
  Cookies.set(COOKIE_KEY.TBMETA, tbMEta, {
    expires: expireTime,
    domain: domain
  });

  Cookies.set(COOKIE_KEY.MARKETING_META, marketingMeta, {
    expires: expireTime,
    domain: domain
  });

  Cookies.set(COOKIE_KEY.CAMPAIGN, campaign, {
    expires: expireTime,
    domain: domain
  });
}
