import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

import { getStorageInfo, STORAGE_KEY } from "@modules/utils/Adsource";

export const purchaseInfo  = (bookingItem) => {
  return {
    transaction_id: bookingItem.booking_info?.booking_id , //부킹_ID
    currency: bookingItem.payment_info?.amount?.currency, //KRW or USD
    value:bookingItem.payment_info?.amount?.sell_price.replace(/,/g, ""), //선택한 룸의 요금 (가격+세금및봉사료)
    tbmeta: getStorageInfo(STORAGE_KEY.T_META_KEY)?.meta_type || "" , //유입경로 (utm_source)
    booking_date: dayjs().local('ko-KR').format("YYYY-MM-DD"), //예약일
    items: [
      {
        item_id: bookingItem.hotel_info?.hotel_id, //호텔아이디
        item_name: bookingItem.hotel_info?.hotel_name ? bookingItem.hotel_info?.hotel_name.replace(/\"/g, '\\"') : "", //호텔명
        item_brand: "", //공급자타입 (TIC/E/ONDA)
        item_category: bookingItem.hotel_info?.country_code, //호텔 국가코드
        item_category2: "", //성급
        item_variant: bookingItem?.booking_info?.cancel_policy_info?.refund_type === "FREE"
                      ? '무료취소'
                      : bookingItem?.booking_info?.cancel_policy_info?.refund_type === "NONE"
                        ? '환불불가'
                        : '취소수수료 발생' , //취소수수료 (무료취소 or 환불불가 or 취소수수료 발생)
        item_list_id: "", //예약하는 룸의 rate_id
        item_list_name: bookingItem?.booking_info?.room_info[0]?.room_name
                        ? bookingItem?.booking_info?.room_info[0]?.room_name.replace(/\"/g, '\\"')
                        : "" , //예약하는 룸의 이름
        check_in: bookingItem?.booking_info?.check_in_date, //체크인 일자
        check_out: bookingItem?.booking_info?.check_out_date, //체크아웃 일자
        coupon: bookingItem?.payment_info?.amount?.discount && bookingItem?.payment_info?.amount?.discount.length > 0
                ? bookingItem?.payment_info?.amount?.discount[0]?.name
                : "" , //사용한프로모션명
        discount: bookingItem?.payment_info?.amount?.discount && bookingItem?.payment_info?.amount?.discount.length > 0
                  ? +bookingItem?.payment_info?.amount?.discount[0]?.amount
                  : 0 , //프로모션 할인금액
        price: bookingItem?.payment_info?.amount?.total_paid.replace(/,/g, ""), // 결제금액 (할인금액 제외)
        quantity: bookingItem?.booking_info?.room_info.length, //예약 객실 수
        size: bookingItem?.booking_info?.room_info.reduce((acc, cur) => {return acc + cur.total_guest_number;}, 0) //총인원
      }
    ]
  }
}