import { checkoutInfo } from "@utils/functions/gtm/checkout";
import { hotelsInfo } from "@utils/functions/gtm/hotel";
import { purchaseInfo } from "@utils/functions/gtm/purchase";

export const gtagDataFormat = ( type, data) => {
  if(type === 'add_to_cart') {
    return checkoutInfo(data);
  } else if(type === 'view_item') {
    return hotelsInfo(data);
  } else if(type === 'purchase') {
    return purchaseInfo(data);
  } else {
    return data;
  }
}