import { Cookies } from "react-cookie-consent";
import { setCookie } from "cookies-next";

export function getAccessToken() {
  if (Cookies.get("access_token")) {
    return Cookies.get("token_type") + " " + Cookies.get("access_token");
  } else {
    return "";
  }
}
export function clearToken() {
  Cookies.set("token_type", null, {
    expires: -1,
    domain: getDomain(),
  });
  Cookies.set("access_token", null, {
    expires: -1,
    domain: getDomain(),
  });
  Cookies.set("refresh_token", null, {
    expires: -1,
    domain: getDomain(),
  });
  Cookies.set("user_id", null, {
    expires: -1,
    domain: getDomain(),
  });
  localStorage.removeItem("refresh_token");
}

export function setRefreshToken(jsonStr, req, res) {
  if (jsonStr) {
    const token = JSON.parse(jsonStr);
    const domain = convertDomain(req.headers["host"]);
    setCookie("token_type", token.token_type, {
      req,
      res,
      maxAge: token.expires_in,
      domain: domain,
    });
    setCookie("access_token", token.access_token, {
      req,
      res,
      maxAge: token.expires_in,
      domain: domain,
    });
    setCookie("refresh_token", token.refresh_token, {
      req,
      res,
      maxAge: token.refresh_token_expires_in,
      domain: domain,
    });
  }
}

export function getDomain() {
  return convertDomain(location.hostname);
}

export function convertDomain(domain) {
  if (domain.includes("kbtoz")) {
    return "kbtoz.com";
  } else if (domain.includes("localhost")) {
    return "localhost";
  } else {
    return domain;
  }
}


export async function setCookieSignInViaApp (json, router) {
    let item = json;
    if (typeof json === "string") {
      item = JSON.parse(json);
    }
    Cookies.set("user_id", item.userId, {
      expires: 1 / 24,
      domain: getDomain(),
    });
    const tokenArr = item.token.split(" ");
    Cookies.set("token_type", tokenArr[0], {
      expires: 1 / 24,
      domain: getDomain(),
    });
    Cookies.set("access_token", tokenArr[1], {
      expires: 1 / 24,
      domain: getDomain(),
    });
    router.reload();
}

export async function setCookieServerToken (req, res, authorization)  {
  const [token_type, access_token] = authorization.split(" ");
  const domain = convertDomain(req.headers["host"]);
  setCookie("token_type", token_type, {
    req,
    res,
    path: "/",
    maxAge: 60 * 60 * 24,
    domain: domain,
  });
  setCookie("access_token", access_token, {
    req,
    res,
    path: "/",
    maxAge: 60 * 60 * 24,
    domain: domain,
  });
}

export function parseJwt (token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function nodeParseJwt (token) {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}