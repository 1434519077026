import { gtagDataFormat } from '@utils/functions/gtm';

/** ---------- GTM ID ---------- */
export const GTM_ID = "GTM-MLKG8LLP";
export const GA_ID_KBPay = "G-V6SXXRNQH7";
/** ---------------------------- */

export const gtagEvent = (type, data) => {
  if (process.env.NEXT_PUBLIC_DEV_ENV !== "development") {
    gtag('event', type, gtagDataFormat(type, data))
  }
}